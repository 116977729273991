<template>
  <base-missing-data-page class="invoice-missing-data" :url-img="$options.imageDataMissing">
    <template v-if="isEmployee" #message>
      {{ message }}
    </template>

    <template v-else #message>
      {{ $t('invoice.no_bills') }}
    </template>
  </base-missing-data-page>
</template>

<script>
import { PAID, RECURRENCE } from '@/constants/entityStatuses';

import BaseMissingDataPage from '@/components/BaseMissingDataPage.vue';

import imageDataMissing from '@/assets/imageMissingData/missing-data-invoice.svg';

export default {
  name: 'InvoicesMissingData',

  components: { BaseMissingDataPage },

  props: {
    isEmployee: { type: Boolean, default: false },
    status: { type: String, required: true },
  },

  computed: {
    isPaid() {
      return this.status === PAID;
    },

    isRecurrence() {
      return this.status === RECURRENCE;
    },

    message() {
      if (this.isPaid) {
        return this.$t('invoice.no_paid_invoices');
      }

      if (this.isRecurrence) {
        return this.$t('invoice.no_recurrence_invoices');
      }

      return this.$t('invoice.employee_no_bills');
    },
  },

  imageDataMissing,
};
</script>
